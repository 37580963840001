<template>
  <a-modal
    v-model:visible="isVisible"
    class="delete-modal"
    :footer="null"
    @cancel="close"
  >
    <!-- <div class="floating-title-icon"><FeatherIcon type="x-circle" :size="16" /></div> -->
    <!-- <p class="ask-text">Are you sure delete {{ name }} Category?</p> -->
    <template #title>
      <div class="delete-title">
        <img :src="require('@/assets/images/icon/x-circle.svg')" />
        <span class="confirm-title">Confirm Delete</span>
      </div>
    </template>
    <div class="delete-modal-content">
      <div class="ask-text">Are you sure delete {{ name }} Category?</div>
      <div class="action-button-modal">
        <a-button
          size="large"
          type="default"
          class="cancel-button"
          :outlined="true"
          @click="close"
        >
          Cancel
        </a-button>
        <a-button
          size="large"
          type="default"
          class="confirm-button"
          @click="onOkay"
          >Delete</a-button
        >
      </div>
    </div>
  </a-modal>
</template>

<script>
import { watch, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';

export default {
  name: 'DeleteCategoryGroupModal',
  props: {
    visible: Boolean,
    name: String,
    level: Number,
    // option: String,
  },
  setup(props, { emit }) {
    const { visible, level } = toRefs(props);
    const store = useStore();
    const { dispatch } = store;
    const isVisible = ref(visible.value);

    const onOkay = async () => {
      const result = await api.deleteCategoryGroup(level.value).catch((e) => {
        console.log(e);
      });
      if (result) {
        await dispatch('config/getAccountCategory');
        console.log(result);
      }
      close();
    };

    watch(
      () => visible.value,
      () => {
        isVisible.value = visible.value;
      }
    );

    const close = () => {
      emit('close');
    };

    return {
      onOkay,
      close,
      isVisible,
    };
  },
};
</script>

<style lang="scss" scoped>
.delete-modal {
  .delete-title {
    display: flex;
    align-content: center;
    .confirm-title {
      margin-left: 10px;
      line-height: 21px;
      color: #272b41;
    }
  }

  .delete-modal-content {
    .ask-text {
      font-size: 14px;
      line-height: 18px;
      color: #5a5f7d;
    }

    .action-button-modal {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      .ant-btn {
        font-weight: 400;
        font-size: 14px;
        height: 44px;
      }
      .confirm-button {
        background: #ff4d4f;
        color: #fff;
      }
      .cancel-button {
        background: #f8f9fb;
        color: #5a5f7d;
        margin-right: 8px;
        border: 1px solid #f1f2f6;
      }
    }
  }
}
</style>
