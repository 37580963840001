<template>
  <a-row
    class="subcat-row"
    :gutter="25"
  >
    <a-col
      :class="{ 'category-card-col': isMobileScreen }"
      :xs="24"
      :md="8"
    >
      <a-card
        class="subcategory-wrapper"
        title="Categories"
      >
        <template #extra>
          <div
            class="add-subcat"
            @click="onAddSubcat"
          >
            <plus-outlined /> Add
          </div>
        </template>
        <a-input-search
          class="search-box"
          placeholder="Search by category"
          @search="onSearch"
        />
        <div class="sort-wrapper">
          <div class="cat-count">{{ subcat.length }} Categories</div>
          <div class="cat-sort">
            Sort by:
            <a-dropdown
              :trigger="['click']"
              :get-popup-container="(trigger) => trigger.parentNode"
            >
              <a
                class="ant-dropdown-link"
                @click.prevent
              >
                {{ sortType }}
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu>
                  <li class="menu-title">Sort by</li>
                  <a-menu-item
                    key="alhpabetical"
                    :class="{ active: sortType === 'Alphabetical' }"
                    @click="selectSortType('Alphabetical')"
                  >
                    Alphabetical
                  </a-menu-item>
                  <a-menu-item
                    key="create_date"
                    :class="{ active: sortType === 'Create Date' }"
                    @click="selectSortType('Create Date')"
                  >
                    Create Date
                  </a-menu-item>
                  <a-menu-divider />
                  <li class="menu-title">Order by</li>
                  <a-menu-item
                    key="asc"
                    :class="{ active: sortDirection === 'asc' }"
                    @click="selectOrder('asc')"
                  >
                    Ascending
                  </a-menu-item>
                  <a-menu-item
                    key="desc"
                    :class="{ active: sortDirection === 'desc' }"
                    @click="selectOrder('desc')"
                  >
                    Descending
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
        <div class="subcat-list">
          <SubCategoryBlock
            v-for="(cat, index) in filteredSubcat"
            :key="index"
            :class="{ active: index === activeIndex }"
            :name="cat.category"
            :color="hexCheck(cat.color)"
            :visible="cat.visible"
            :level="level"
            @click="onChangeSubcat(cat.category, index)"
          />
        </div>
      </a-card>
    </a-col>
    <a-col :md="16">
      <CategoryKeywordBlock
        :selectedSubcat="selectedSubcat"
        :level="level"
        :activeTab="activeKey"
      />
    </a-col>
    <AddModal
      :visible="addVisible"
      option="add"
      :level="level"
      @close="toggleAddModal(false)"
    />
  </a-row>
</template>

<script>
import dayjs from 'dayjs';
import { ref, computed, onMounted, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import { PlusOutlined, DownOutlined } from '@ant-design/icons-vue';
import SubCategoryBlock from './SubCategoryBlock.vue';
import CategoryKeywordBlock from './CategoryKeywordBlock.vue';
import AddModal from './CategoryModal.vue';
import helper from '@/services/helper';

export default {
  components: {
    PlusOutlined,
    DownOutlined,
    SubCategoryBlock,
    CategoryKeywordBlock,
    AddModal,
  },
  props: {
    subcat: Object,
    level: Number,
    activeKey: Number,
  },
  setup(props) {
    // let selectedSubcat = ref(props.subcat[0].category);
    let { subcat, activeKey } = toRefs(props);
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    let selectedSubcat = ref();
    let activeIndex = ref(0);
    let addVisible = ref(false);
    let searchFilter = ref('');
    let sortDirection = ref('asc');
    let sortType = ref('Alphabetical');
    let filteredSubcat = computed(() => {
      let filtered = props.subcat.filter((cat) =>
        cat.category.includes(searchFilter.value)
      );
      let sortFiltered = filtered.sort(function (a, b) {
        if (sortType.value == 'Alphabetical') {
          if (sortDirection.value == 'asc') {
            return a.category.localeCompare(b.category, 'en');
            // return a.category > b.category
            //   ? 1
            //   : b.category > a.category
            //   ? -1
            //   : 0;
          } else {
            return b.category.localeCompare(a.category, 'en');
            // return b.category > a.category
            //   ? 1
            //   : a.category > b.category
            //   ? -1
            //   : 0;
          }
        } else {
          const ca = dayjs(a.createdAt).valueOf();
          const cb = dayjs(b.createdAt).valueOf();
          // console.log(ca, cb);

          if (sortDirection.value == 'asc') {
            return ca - cb;
          } else {
            return cb - ca;
          }
        }
      });

      //  find 'others'and move it to the first position
      const indexOthers = sortFiltered.findIndex(
        (ele) => ele.category === 'others'
      );
      if (indexOthers > -1) {
        const filteredList = helper.sortOtherItemAtLast(
          sortFiltered,
          indexOthers
        );
        return filteredList;
      }
      return sortFiltered;
    });

    const selectOrder = (e) => {
      sortDirection.value = e;
      activeIndex.value = 0;
      selectedSubcat.value = filteredSubcat.value[0].category;
    };

    const selectSortType = (e) => {
      sortType.value = e;
    };

    const onSearch = (e) => {
      searchFilter.value = e;
      activeIndex.value = 0;
      selectedSubcat.value = filteredSubcat.value[0].category;
    };

    const onChangeSubcat = (cat, index) => {
      activeIndex.value = index;
      selectedSubcat.value = cat;
    };

    const onAddSubcat = () => {
      addVisible.value = true;
    };

    const toggleAddModal = (state) => {
      // console.log('close');
      addVisible.value = state;
    };

    const hexCheck = (color) => {
      if (color.charAt(0) != '#') {
        return '#' + color;
      } else {
        return color;
      }
    };

    onMounted(() => {
      const indexOthers = subcat.value.findIndex(
        (ele) => ele.category === 'others'
      );
      if (indexOthers > -1) {
        const filteredList = helper.sortOtherItemAtLast(
          subcat.value,
          indexOthers
        );
        selectedSubcat.value = filteredList[0].category;
      } else {
        selectedSubcat.value = props.subcat[0].category;
      }
    });

    watch(
      () => activeKey.value,
      () => {
        activeIndex.value = 0;
        selectedSubcat.value = filteredSubcat.value[0].category;
      }
    );

    return {
      searchFilter,
      filteredSubcat,
      selectedSubcat,
      activeIndex,
      onSearch,
      onChangeSubcat,
      onAddSubcat,
      addVisible,
      toggleAddModal,
      hexCheck,
      selectOrder,
      sortType,
      selectSortType,
      sortDirection,
      isMobileScreen,
    };
  },
};
</script>

<style lang="scss">
.subcat-row {
  row-gap: 24px;
  .category-card-col {
    margin-bottom: 20px;
  }
}
.subcategory-wrapper {
  min-height: 500px;
  height: 100%;
  margin-bottom: 0;
  .ant-card-head {
    border: none;
    padding: 0 15px !important;
    .add-subcat {
      background: #3371ff;
      border-radius: 4px;
      padding: 9px 20px;
      color: #fff;
      cursor: pointer;
    }
  }
  .ant-card-body {
    padding-top: 0;
    padding-left: 15px !important;
    padding-right: 15px !important;
    .search-box {
      border-radius: 16px;
      border: none;
      background: #f8f9fb;
      margin-bottom: 16px;
      height: 36px;
      input {
        background: #f8f9fb;
        color: #adb4d2;
      }
    }
    .sort-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .cat-sort {
        .ant-dropdown-link {
          border: 1px solid #e3e6ef;
          padding: 6px 10px;
          border-radius: 4px;
          margin-left: 8px;
          color: #5a5f7d;
          display: inline-block;
        }
      }
      @media (max-width: 1200px) {
        // flex-direction: column;
        align-items: flex-start;
        .cat-count {
          margin-bottom: 12px;
        }
      }
      @media (max-width: 768px) {
        a {
          margin-left: 0;
          margin-top: 6px;
        }
      }
    }
    .subcat-list {
      margin-top: 16px;
      .subcat {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        border-radius: 6px;
        cursor: pointer;
        &.active {
          background: #f4f5f7;
        }
        .subcat-name {
          display: flex;
          align-items: center;
          i {
            margin-right: 8px;
          }
        }
        .switch-more {
          align-items: center;
          min-width: 66px;
          .anticon {
            color: #adb4d2;
            margin-left: 8px;
            cursor: pointer;
            &.hide {
              display: none;
            }
          }
        }
        @media (max-width: 1200px) {
          // flex-direction: column;
          .switch-more {
            align-self: flex-end;
          }
        }
      }
    }
  }
}
.ant-dropdown-menu {
  padding: 8px 0;
  min-width: 50px;
  .ant-dropdown-menu-item-divider {
    padding: 0;
  }
  .menu-title {
    font-size: 11px;
    color: #9992b8;
  }
}
</style>
