<template>
  <a-card
    class="keyword-wrapper"
    :title="'Category: ' + selectedSubcat"
  >
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane
        key="1"
        tab="Keyword"
      >
        <KeywordInputBlock
          :hasTitle="true"
          :hasSentiment="true"
          :noPadding="true"
          :current-word-list="keywordList"
          :onAdd="onAddKeyword"
        />
        <div class="keyword-row">
          <a-skeleton
            v-if="loading"
            :loading="loading"
            active
          />
          <TableFilterWrapper
            v-else
            :rowKey="'originalKeyword'"
            :filterOption="filterOption"
            :isRowSelection="true"
            :isTimeFromNow="true"
            :isTypeTag="true"
            :tableData="keywordList"
            :columns_props="columnConfig"
            :actions="actions"
            :pagination="{
              current: currentPage,
              showQuickJumper: false,
              showSizeChanger: true,
              total: keywordList.length,
              showTotal: () => '',
            }"
            @toggleAction="onToggleAction"
            @changePage="onChangePage"
          />
        </div>
      </a-tab-pane>
      <a-tab-pane
        key="2"
        tab="Exclude Keyword"
      >
        <KeywordInputBlock
          :hasTitle="true"
          :title="'Enter Exclude Keyword'"
          :hasSentiment="true"
          :noPadding="true"
          :current-word-list="excludeKeywordList"
          @add="onAddExcludeKeyword"
        />
        <div class="keyword-row">
          <a-skeleton
            v-if="loading"
            :loading="loading"
            active
          />
          <TableFilterWrapper
            v-else
            :rowKey="'originalKeyword'"
            :filterOption="filterOption"
            :isRowSelection="true"
            :isTimeFromNow="true"
            :isTypeTag="true"
            :tableData="excludeKeywordList"
            :columns_props="columnConfig"
            :actions="actions"
            :pagination="{
              current: currentPage,
              showQuickJumper: false,
              showSizeChanger: true,
              total: excludeKeywordList.length,
              showTotal: () => '',
            }"
            @toggleAction="onToggleExAction"
            @changePage="onChangePage"
          />
        </div>
      </a-tab-pane>
    </a-tabs>
    <KeywordModal
      :level="level"
      :visible="modalVisible"
      :record="focusRecord"
      @close="keywordModalClose"
    />
    <ConfirmDelete
      :closable="true"
      :visible="deleteVisible"
      @close="deleteVisible = false"
      @confirm="onConfirmDelete"
    >
      <span v-if="isMultipleRecord">
        Are you sure to delete these {{ focusRecord.length }} keywords?
      </span>
      <span v-else> Are you sure to delete this keyword ? </span>
    </ConfirmDelete>
  </a-card>
</template>

<script>
import { ref, watch } from 'vue';
import { notification } from 'ant-design-vue';
import TableFilterWrapper from '@/components/Table/TableFilterWrapper.vue';
import KeywordInputBlock from '@/views/Settings/KeywordInputBlock.vue';
// import KeywordInput from './KeywordInput.vue';
import KeywordModal from './KeywordModal.vue';
import ConfirmDelete from '@/components/ConfirmDelete.vue';

import api from '@/services/api';
import helper from '@/services/helper';

export default {
  components: {
    TableFilterWrapper,
    KeywordModal,
    KeywordInputBlock,
    ConfirmDelete,
    // KeywordInput,
  },
  props: {
    selectedSubcat: String,
    level: Number,
    activeTab: Number,
  },
  setup(props) {
    const activeKey = ref('1');
    const keywordList = ref([]);
    const excludeKeywordList = ref([]);
    const modalVisible = ref(false);
    const deleteVisible = ref(false);
    const focusRecord = ref([]);
    const isMultipleRecord = ref(false);
    const focusMode = ref('keyword');
    const loading = ref(true);
    const columnConfig = [
      {
        title: 'Keyword',
        dataIndex: 'keyword',
        key: 'keyword',
        fixed: 'left',
        sorter: helper.getTableSortFn('string', 'keyword'),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        slots: { customRender: 'type' },
        sorter: helper.getTableSortFn('string', 'type'),
        width: 100,
      },
      {
        title: 'Sentiment',
        dataIndex: 'sentiment',
        key: 'sentiment',
        slots: { customRender: 'sentiment' },
        sorter: helper.getTableSortFn('number', 'sentiment'),
        width: 130,
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        slots: { customRender: 'updated_at' },
        sorter: helper.getTableSortFn('date-x', 'updatedAt'),
        width: 150,
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
        width: 150,
      },
    ];
    const actions = [
      { name: 'Edit', value: 'edit', icon: 'edit' },
      { name: 'Delete', value: 'delete', icon: 'trash-2' },
    ];
    const filterOption = {
      key: 'type',
      title: 'Type:',
      defaultValue: '',
      filterValueList: [
        { value: '', title: 'All type' },
        { value: 'word', title: 'Keyword' },
        { value: 'hashtag', title: 'Hashtag' },
        { value: 'username', title: 'Username' },
      ],
    };

    const convertKeyword = (obj) => {
      const { id: keyword, createdAt, updatedAt, value } = obj;
      if (keyword.charAt(0) === '#') {
        return {
          originalKeyword: keyword,
          editingKeyword: keyword.slice(1),
          keyword: `#${keyword.slice(1)}`,
          type: 'hashtag',
          createdAt,
          updatedAt,
          category: props.selectedSubcat,
          sentiment: value,
        };
      }
      if (keyword.indexOf('username:') === 0) {
        return {
          originalKeyword: keyword,
          keyword: `@${keyword.slice(9)}`,
          editingKeyword: `${keyword.slice(9)}`,
          type: 'username',
          createdAt,
          updatedAt,
          category: props.selectedSubcat,
          sentiment: value,
        };
      }
      return {
        originalKeyword: keyword,
        keyword,
        type: 'word',
        createdAt,
        updatedAt,
        category: props.selectedSubcat,
        sentiment: value,
      };
    };

    const getKeyword = async () => {
      loading.value = true;
      const result = await api
        .getCategoryKeywords(props.level, props.selectedSubcat)
        .catch((e) => {
          console.log(e);
        });
      if (result && result.message) {
        const list = result.message.map((obj) => convertKeyword(obj));
        keywordList.value = list;
      }
      loading.value = false;
    };

    const getExcludeKeyword = async () => {
      loading.value = true;
      const result = await api
        .getCategoryExcludeKeywords(props.level, props.selectedSubcat)
        .catch((e) => {
          console.log(e);
        });
      if (result && result.message) {
        const list = result.message.map((obj) => convertKeyword(obj));
        excludeKeywordList.value = list;
      }
      loading.value = false;
    };

    const setAction = (actionObj) => {
      const { action, record } = actionObj;
      if (action === 'edit') {
        focusRecord.value = record;
        modalVisible.value = true;
      } else if (action === 'delete') {
        focusRecord.value = record;
        deleteVisible.value = true;
        isMultipleRecord.value = false;
      } else if (action === 'deleteMultiple') {
        focusRecord.value = record;
        deleteVisible.value = true;
        isMultipleRecord.value = true;
      }
    };

    const onToggleAction = (actionObj) => {
      focusMode.value = 'keyword';
      setAction(actionObj);
    };

    const onToggleExAction = (actionObj) => {
      focusMode.value = 'exclude';
      setAction(actionObj);
    };

    const keywordModalClose = (update) => {
      modalVisible.value = false;
      if (update) {
        if (focusMode.value === 'keyword') {
          getKeyword();
        } else if (focusMode.value === 'exclude') {
          getExcludeKeyword();
        }
      }
    };
    const toggleDeleteModal = (state) => {
      deleteVisible.value = state;
    };

    const onAddKeyword = async (obj) => {
      const list = helper.prepareKeywordList(obj);
      const newList = list.map((k) => {
        return { word: k, value: obj.sentimentValue };
      });
      let isSuccess = true;
      const result = await api
        .bulkCreateCategoryKeyword(props.level, props.selectedSubcat, newList)
        .catch((e) => {
          notification.error({
            message: 'Error',
            description: 'Keyword add failed, ' + e.response.data.message,
          });
          isSuccess = false;
        });
      if (result && result.code === 200) {
        notification.success({
          message: 'Success',
          description: 'Keyword added',
        });
        getKeyword();
      }
      return isSuccess;
    };

    const onAddExcludeKeyword = async (obj) => {
      let isSuccess = true;
      const list = helper.prepareKeywordList(obj);
      const newList = list.map((k) => {
        return { word: k, value: obj.sentimentValue };
      });
      const result = await api
        .bulkCreateCategoryExcludeKeyword(
          props.level,
          props.selectedSubcat,
          newList,
        )
        .catch((e) => {
          notification.error({
            message: 'Error',
            description:
              'Exclude keyword add failed. ' + e.response.data.message,
          });
          isSuccess = false;
        });
      if (result && result.code === 200) {
        notification.success({
          message: 'Success',
          description: 'Exclude keyword added',
        });
        getExcludeKeyword();
      }
      return isSuccess;
    };

    const onConfirmDelete = async () => {
      if (isMultipleRecord.value) {
        if (focusMode.value === 'keyword') {
          const result = await api
            .bulkDeleteCategoryKeyword(
              props.level,
              props.selectedSubcat,
              focusRecord.value,
            )
            .catch((e) => {
              console.log(e);
            });
          if (result) {
            getKeyword();
          }
        } else if (focusMode.value === 'exclude') {
          const result = await api
            .bulkDeleteCategoryExcludeKeyword(
              props.level,
              props.selectedSubcat,
              focusRecord.value,
            )
            .catch((e) => {
              console.log(e);
            });
          if (result) {
            getExcludeKeyword();
          }
        }
      } else {
        if (focusMode.value === 'keyword') {
          const result = await api
            .deleteCategoryKeyword(
              props.level,
              focusRecord.value.originalKeyword,
              props.selectedSubcat,
            )
            .catch((e) => {
              console.log(e);
            });
          if (result) {
            getKeyword();
          }
        } else if (focusMode.value === 'exclude') {
          const result = await api
            .deleteCategoryKeyword(
              props.level,
              focusRecord.value.originalKeyword,
              props.selectedSubcat,
            )
            .catch((e) => {
              console.log(e);
            });
          if (result) {
            getExcludeKeyword();
          }
        }
      }

      deleteVisible.value = false;
    };

    // onMounted(async () => {
    //   getKeyword();
    //   getExcludeKeyword();
    // });

    let currentPage = ref(1);

    // subcat changed
    watch(
      () => props.selectedSubcat,
      () => {
        currentPage.value = 1;
        getKeyword();
        getExcludeKeyword();
      },
    );

    // keyword or exclude keyword tabs changed
    watch(
      () => activeKey.value,
      () => {
        currentPage.value = 1;
      },
    );

    // activeTab (smart tab) changed
    watch(
      () => props.activeTab,
      () => {
        currentPage.value = 1;
        activeKey.value = '1';
        getKeyword();
        getExcludeKeyword();
      },
    );

    const onChangePage = (e) => {
      currentPage.value = e.current;
    };

    return {
      activeKey,
      keywordList,
      excludeKeywordList,
      columnConfig,
      actions,
      onToggleAction,
      onToggleExAction,
      modalVisible,
      deleteVisible,
      keywordModalClose,
      toggleDeleteModal,
      focusRecord,
      isMultipleRecord,
      onConfirmDelete,
      loading,
      filterOption,
      onAddKeyword,
      onAddExcludeKeyword,
      onChangePage,
      currentPage,
    };
  },
};
</script>

<style lang="scss">
.keyword-wrapper {
  min-height: 100%;
  .ant-card-body {
    padding-top: 10px;
    .add-keyword-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .keyword-dropdown {
        margin-top: 6px;
        width: 145px;
        border-radius: 4px;
        color: #5a5f7d;
        display: inline-block;
        white-space: nowrap;
        .ant-select-selector {
          height: 50px;
          .ant-select-selection-item {
            align-self: center;
          }
        }
      }
      .keyword-input {
        margin-top: 6px;
        width: auto;
        border-radius: 4px;
        margin-left: 8px;
        flex-grow: 100;
      }
      .sentiment-dropdown {
        margin-top: 6px;
        border: 1px solid #e3e6ef;
        padding: 12px;
        border-radius: 4px;
        margin-left: 8px;
        color: #5a5f7d;
        display: flex;
        align-items: center;
        .anticon {
          margin-left: 8px;
        }
      }
      .ant-btn-primary {
        margin-top: 6px;
        height: 50px;
        margin-left: 8px;
        padding: 20px;
      }
    }
    .search-keyword-row {
      display: flex;
      justify-content: space-between;
      padding: 18px 0;
      .search-box {
        max-width: 250px;
        height: 36px;
        border-radius: 16px;
        border: none;
        background: #f8f9fb;
        input {
          background: #f8f9fb;
          color: #adb4d2;
        }
        @media (max-width: 768px) {
          max-width: unset;
        }
      }
      .keyword-sort {
        .keyword-type-dropdown {
          border: 1px solid #e3e6ef;
          padding: 6px 10px;
          border-radius: 4px;
          margin-left: 8px;
          color: #5a5f7d;
          display: inline-block;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
        .keyword-sort {
          margin-top: 6px;
          align-self: flex-end;
        }
      }
    }
    .keyword-row {
      overflow: hidden;
      margin-top: 16px;
      // .ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
      //   line-height: 29px;
      // }
      .search-bar,
      #filter-select {
        margin-left: 2px;
        margin-right: 2px;
        margin-top: 2px;
      }
    }
  }
}
.add-modal {
  .ant-modal-footer {
    div {
      button:first-child {
        display: none;
      }
    }
  }
}
.delete-modal {
  .ant-modal-body {
    .floating-title-icon {
      i {
        color: #ff4d4f;
      }
    }
  }
  .ant-modal-footer {
    .ant-btn-primary {
      background: #ff4d4f;
    }
  }
}
</style>
