<template>
  <div class="category-setting">
    <div class="page-content">
      <a-row :gutter="25">
        <a-col>
          <div class="category-title">Category</div>
        </a-col>
      </a-row>
      <a-row :gutter="25">
        <a-col :xs="24">
          <SmartTab
            :tabs="category"
            content="subcat"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import SmartTab from '@/components/Tab/SmartTab.vue';

export default {
  name: 'Category',
  components: {
    SmartTab,
  },
  setup() {
    const store = useStore();
    const category = computed(() => store.getters['config/categoryData']);

    return {
      category,
    };
  },
};
</script>

<style lang="scss">
.category-setting {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  .page-content {
    padding: 30px;
    .category-title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 18px;
    }
  }
}
</style>
