<template>
  <div>
    <a-tabs
      v-model:activeKey="activeKey"
      :animated="false"
      class="smart-tabs"
      @tabClick="onTabClick"
    >
      <a-tab-pane
        v-for="(tab, index) in tabs"
        :key="tab.level"
      >
        <template #tab>
          <span
            class="tab-wrapper"
            :class="tabs.length - hideTabCount > index ? '' : 'hide'"
          >
            <div class="tab-name-wrapper">
              {{ tab.name }}
            </div>
            <a-dropdown
              :trigger="['click']"
              :getPopupContainer="(trigger) => trigger.parentNode"
              placement="bottomRight"
              overlayClassName="smarttab-dropdown"
            >
              <more-outlined />
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="onReorderCat">
                    <FeatherIcon
                      type="refresh-cw"
                      size="18"
                    />
                    <span style="margin-left: 10px">Reorder</span>
                  </a-menu-item>
                  <a-menu-item @click="onRenameCat(tab.name, tab.level)">
                    <FeatherIcon
                      type="edit"
                      size="18"
                    />
                    <span style="margin-left: 10px">Rename</span>
                  </a-menu-item>
                  <a-menu-item @click="onDeleteCat(tab.name, tab.level)">
                    <FeatherIcon
                      type="trash-2"
                      size="18"
                    />
                    <span style="margin-left: 10px">Delete</span>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </span>
        </template>
        <SubCategory
          v-if="content == 'subcat'"
          :subcat="tab.categories"
          :level="tab.level"
          :activeKey="activeKey"
        />
      </a-tab-pane>
      <a-tab-pane
        v-if="isMoreVisible"
        :key="'more'"
      >
        <template #tab>
          <span
            :class="isMoreActive ? 'more-active' : ''"
            @click.stop.prevent
          >
            <a-dropdown
              v-model:visible="moreOptionVisible"
              :trigger="['click']"
              placement="bottomRight"
              :getPopupContainer="(trigger) => trigger.parentNode"
            >
              <span class="more-tab">More <DownOutlined /></span>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    v-for="(tab, index) in tabs"
                    :key="tab.level"
                    :class="dropdownClass(index)"
                    @click="onMoreDropdownClick($event, index)"
                  >
                    <span>{{ tab.name }}</span>
                    <a-dropdown
                      :trigger="['click']"
                      :getPopupContainer="(trigger) => trigger.parentNode"
                      placement="bottomRight"
                      overlayClassName="smarttab-dropdown"
                    >
                      <more-outlined />
                      <template #overlay>
                        <a-menu @click="handleMenuClick">
                          <a-menu-item @click="onReorderCat">
                            <FeatherIcon
                              type="refresh-cw"
                              size="18"
                            />
                            <span style="margin-left: 10px">Reorder</span>
                          </a-menu-item>
                          <a-menu-item
                            @click="onRenameCat(tab.name, tab.level)"
                          >
                            <FeatherIcon
                              type="edit"
                              size="18"
                            />
                            <span style="margin-left: 10px">Rename</span>
                          </a-menu-item>
                          <a-menu-item
                            @click="onDeleteCat(tab.name, tab.level)"
                          >
                            <FeatherIcon
                              type="trash-2"
                              size="18"
                            />
                            <span style="margin-left: 10px">Delete</span>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </span>
        </template>
      </a-tab-pane>
      <a-tab-pane disabled>
        <template #tab>
          <span
            v-if="tabs && tabs.length < 10"
            class="plus-tab"
            @click="onAddCat"
          >
            <plus-outlined />
          </span>
        </template>
      </a-tab-pane>
    </a-tabs>
    <CategoryGroupModal
      :visible="editModalVisible"
      :name="modalCatName"
      :level="modalCatLevel"
      :option="modalOption"
      @close="toggleEditModal(false)"
    />
    <DeleteModal
      :visible="deleteModalVisible"
      :name="deleteCatName"
      :level="modalCatLevel"
      @close="toggleDeleteModal(false)"
    />
    <ReorderModal
      :visible="reorderModalVisible"
      :tabs="tabs"
      @close="toggleReorderModal(false)"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import {
  MoreOutlined,
  PlusOutlined,
  DownOutlined,
} from '@ant-design/icons-vue';
import SubCategory from '@/views/Settings/CategorySetting/SubCategory.vue';
import CategoryGroupModal from '@/views/Settings/CategorySetting/CategoryGroupModal.vue';
import DeleteModal from '@/views/Settings/CategorySetting/DeleteModal.vue';
import ReorderModal from '@/views/Settings/CategorySetting/ReorderModal.vue';

export default {
  name: 'SmartTab',
  components: {
    MoreOutlined,
    PlusOutlined,
    DownOutlined,
    SubCategory,
    CategoryGroupModal,
    DeleteModal,
    ReorderModal,
  },
  props: {
    tabs: Object,
    content: String,
  },
  setup(props, context) {
    const store = useStore();
    const containerPadding = ref(60);

    let activeKey = ref(0);
    let sideNavCollapsed = computed(() => store.state.sideNavCollapsed);
    let isSmallScreen = computed(() => store.state.isSmallScreen);
    let sideNavWidth = ref(280);
    let windowWidth = ref(window.innerWidth);
    let tabContainer = ref(0);
    let tabComponent = computed(() => props.tabs.length * 150 + 50);
    let isMoreVisible = computed(() => tabContainer.value < tabComponent.value);
    let isMoreActive = ref(false);
    let hideTabCount = ref(0);

    let editModalVisible = ref(false);
    let deleteModalVisible = ref(false);
    let reorderModalVisible = ref(false);
    let deleteCatName = ref('');
    let modalCatLevel = ref();
    let modalCatName = ref();
    let modalOption = ref('');
    let moreOptionVisible = ref(false);

    const handleMenuClick = () => {
      moreOptionVisible.value = false;
    };

    const calcTabs = () => {
      if (isMoreVisible.value) {
        hideTabCount.value++;
        if (
          tabContainer.value <
          tabComponent.value - 150 * hideTabCount.value
        ) {
          calcTabs();
        } else {
          hideTabCount.value++;
        }
      }
    };

    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
      tabContainer.value =
        windowWidth.value - sideNavWidth.value - containerPadding.value;
      hideTabCount.value = 0;
      calcTabs();
    };

    onMounted(() => {
      window.addEventListener('resize', onWidthChange);
      onWidthChange();
    });
    onUnmounted(() => window.removeEventListener('resize', onWidthChange));

    watch(
      () => sideNavCollapsed.value,
      () => {
        if (sideNavCollapsed.value || isSmallScreen.value) {
          sideNavWidth.value = 76;
        } else {
          sideNavWidth.value = 280;
        }
        onWidthChange();
      }
    );

    const onTabClick = (index) => {
      context.emit('smarttabClick');
      if (index != 'more') {
        isMoreActive.value = false;
      }
    };

    const onMoreDropdownClick = (e, index) => {
      if (activeKey.value != index) {
        moreOptionVisible.value = false;
      }
      activeKey.value = index;
      isMoreActive.value = true;
    };

    const dropdownClass = (index) => {
      if (props.tabs.length - hideTabCount.value > index) {
        return 'hide';
      } else if (activeKey.value == index) {
        return 'active';
      } else {
        return '';
      }
    };

    const onAddCat = () => {
      modalOption.value = 'add';
      editModalVisible.value = true;
    };
    const onRenameCat = (name, level) => {
      modalCatName.value = name;
      modalCatLevel.value = level;
      modalOption.value = 'rename';
      editModalVisible.value = true;
    };
    const onReorderCat = () => {
      reorderModalVisible.value = true;
    };
    const onDeleteCat = (name, level) => {
      modalCatLevel.value = level;
      deleteCatName.value = name;
      deleteModalVisible.value = true;
    };

    const toggleEditModal = (state) => {
      editModalVisible.value = state;
      onWidthChange();
    };
    const toggleDeleteModal = (state) => {
      if (activeKey.value > props.tabs.length - 1) {
        activeKey.value = 0;
      }
      deleteModalVisible.value = state;
      onWidthChange();
    };
    const toggleReorderModal = (state) => {
      reorderModalVisible.value = state;
      onWidthChange();
    };

    return {
      activeKey,
      isMoreVisible,
      isMoreActive,
      hideTabCount,
      dropdownClass,
      onTabClick,
      onMoreDropdownClick,
      onAddCat,
      onRenameCat,
      onReorderCat,
      onDeleteCat,
      toggleEditModal,
      toggleDeleteModal,
      toggleReorderModal,
      editModalVisible,
      deleteModalVisible,
      reorderModalVisible,
      deleteCatName,
      modalCatLevel,
      modalCatName,
      modalOption,
      moreOptionVisible,
      handleMenuClick,
    };
  },
};
</script>

<style lang="scss">
.ant-tabs.smart-tabs {
  > .ant-tabs-bar {
    border-bottom: 2px solid #dadcf1;
    .ant-tabs-nav-container {
      overflow: visible;
      .ant-tabs-nav-wrap {
        overflow: visible;
        .ant-tabs-nav-scroll {
          overflow: visible;
          .ant-tabs-nav {
            .ant-tabs-tab {
              margin: 0;
              padding: 0;
              .tab-wrapper {
                min-width: 150px;
                max-width: 150px;
                overflow: hidden;
              }
              .tab-name-wrapper {
                max-width: 120px;
                margin: 0 auto;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .more-tab {
                min-width: 150px;
                max-width: 150px;
                margin: 0 auto;
                overflow: hidden;
                text-overflow: ellipsis;
                justify-content: center;
              }
              > span {
                // justify-content: center;
                align-items: center;
                height: 36px;
                overflow: hidden;
                text-overflow: ellipsis;
                &.hide {
                  display: none;
                  min-width: 0;
                }
                &.more-active {
                  padding-bottom: 10px;
                  border-bottom: 2px solid #3371ff;
                  color: #3371ff;
                }
              }
              .anticon {
                padding: 12px 0;
                margin-left: 14px;
                margin-right: 0;
              }
              &.ant-tabs-tab-active {
                .tab-name-wrapper {
                  max-width: 120px !important;
                }
              }
              &:not(.ant-tabs-tab-active):not(.ant-tabs-tab-disabled) {
                color: #5a5f7d;
                > span {
                  > .anticon {
                    display: none;
                  }
                }
                &:hover {
                  color: #3371ff;
                }
              }
              &.ant-tabs-tab-active {
                color: #3371ff;
              }
              &.ant-tabs-tab-disabled {
                cursor: pointer;
                color: #272b41;
                width: auto;
                > span {
                  width: auto;
                  padding: 0 12px;
                }
                .anticon {
                  margin: 0;
                }
              }
            }
            .ant-tabs-ink-bar {
              bottom: -1px;
            }
          }
        }
      }
      &.ant-tabs-nav-container-scrolling {
        padding: 0;
        .ant-tabs-tab-arrow-show {
          display: none;
        }
      }
    }
  }
}
.ant-dropdown {
  .ant-dropdown-menu-item {
    &.hide {
      display: none;
    }
    &.active {
      background: rgba(51, 113, 255, 0.05);
      color: #3371ff;
    }
    &:not(.active) {
      color: #5a5f7d;
      > span {
        > .anticon {
          display: none;
        }
      }
      &:hover {
        color: #3371ff;
      }
    }
  }
}

.ant-dropdown-menu-title-content {
  justify-content: space-between;
}

// .smarttab-dropdown {
//   margin-top: 14px;
// }
</style>
